.contenedor-operaciones-sql {
    width: 80%;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    
}

.titulo-operaciones {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.subir-archivo {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.subir-archivo:hover {
    background: #f9f9f9;
}

.btn-subir {
    display: inline-block;
    background: #007bff;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    border: none;
    transition: background 0.3s;
}

.btn-subir:hover {
    background: #0056b3;
}

.select-consulta {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
}

.input-columnas {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.btn-ejecutar {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #00c389 !important;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s;
}

.btn-ejecutar:hover {
    background: #218838;
}

.btn-ejecutar:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.select-consulta button {
    padding: 12px 18px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: #343a40; /* Color oscuro para mejor contraste */
    color: white;
    transition: background 0.3s ease, transform 0.2s ease;
}

.select-consulta button:hover {
    background-color: #23272b;
    transform: scale(1.05);
}

.btn-activo {
    background-color: #007bff;
    color: white;
    box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.3);
}

.btn-cargue {
    background-color: #00c389 !important;
    border-color: #00c389 !important;
    color: white;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background 0.3s ease, transform 0.2s ease;
    border: none;
}

.btn-cargue:hover {
    background-color: #009874;
    transform: scale(1.05);
}

.btn-template {
    background-color: #ff7f41 !important;
    border-color: #ff7f41 !important;
    color: white;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background 0.3s ease, transform 0.2s ease;
    border: none;
}

.btn-template:hover {
    background-color: #e6692d;
    transform: scale(1.05);
}

.btn-activo {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.08);
}

.contenedor-columnas {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
}

.grupo-input {
    display: flex;
    flex-direction: column;
    gap: 3px; /* Reduce el espacio entre el label y el input */
}

.label-input {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin-bottom: 2px; /* Espaciado mínimo con el input */
}

.input-columnas {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}